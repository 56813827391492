import React from "react";
import { Bar } from "react-chartjs-2";

const DoubleBar = () => {
  // Hardcoded data for the chart
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Air",
        data: [500, 600, 700, 800, 750, 650, 500, 450, 400, 350, 300, 250],
        backgroundColor: "#5e72e4",
        hoverBackgroundColor: "#324cdd",
      },
      {
        label: "Sewa",
        data: [450, 550, 650, 700, 600, 500, 400, 350, 300, 250, 200, 150],
        backgroundColor: "orange",
        hoverBackgroundColor: "#f4365c",
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
    },
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, grid: { color: "#e9e9e9" } },
    },
  };

  return (
    <div style={{ height: "300px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default DoubleBar;
