import { useState } from 'react';
import axios from 'axios';
import img from '../assets/login-bg1.png';
import logo from '../assets/logo.png';
import Swal from 'sweetalert2';

const Login = () => {

    
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await axios.post('http://bpohasil.click:8080/ca/login', {
                username: name,
                password: password,
            });
            
            
            localStorage.setItem('token', response.data.token);
            Swal.fire({
                icon: "success",
                title: "Welcome",
                text: "Login successful",
                timer: 2500
            });
            setTimeout(()=>{
                window.location.replace('/')
            },2000)
        } catch (error) {
            
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Invalid username or password",
                timer: 1500
            });
        }
    };

    return (
        <div className="row g-0 login-bg fixed-top" style={{ zIndex: '100' }}>
            <div className="col-lg-7 d-none d-lg-block">
                <img src={img} style={{ height: '100vh', width: '100%' }} alt="Background" />
            </div>
            <div className="col-lg-5 login">
                <div className='container text-center'>
                    <div className='card p-0 log shadow-none border-0'>
                        <div className='card-body p-1 p-lg-0'>
                            <img src={logo} className='logo' alt="Logo" />
                            <div className='log-title mt-5 mb-1 fw-medium fs-5'>Sign in</div>
                            <form onSubmit={handleSubmit}>
                                <div className='inputs'>
                                    <input
                                        type='text'
                                        placeholder='Username*'
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <input
                                        type='password'
                                        placeholder='Password*'
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button type='submit' className=''>SIGN IN</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
