const Table = () => {
    return ( 
          <table className="table">
            <thead>
              <tr className="text-center">
                <th className="p-1 ">Name</th>
                <th className="p-1">No Akaun</th>
                <th className="p-1">No Harta</th>
                <th className="p-1">Caj</th>
                <th className="p-1">Tunggakan</th>
                <th className="p-1">Bayaran</th>


              </tr>
            </thead>
            <tbody>
                <tr className='my-2  text-center' >
                  <td className="p-2 text-" style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '150px'
                  }}>Ali</td>
                  
                  <td className="p-2">hsayd hdilhdbqwdqw</td>
                  <td className="p-2">sajdkgkqwd</td>
                  <td className="p-2">RM 222333</td>
                  <td className="p-2">RM 245</td>
                  <td className="p-2">RM 3443</td>


                </tr>

            </tbody>
          </table>
     );
}
 
export default Table;