import React from 'react';
import { a, NavLink } from 'react-router-dom';

const Navbar = () => {

  // logout
const handleLogout = () =>{
  localStorage.removeItem('token')
  localStorage.removeItem('paths')
  window.location.replace('/');
}

const handleHome = () =>{
  
  localStorage.removeItem('paths')
  window.location.replace('/');
}
 
const path_name = localStorage.getItem("path_name")

  return (
    <nav className="navbar navbar-expand shadow-none">
      <div className="container-fluid py-1 px-1">
        <div className="row d-flex align-items-center" style={{ width: '100%' }}>
          <div className="col-lg-7 col-xl-8">
            <nav>
              <ol className="breadcrumb my-1 bg-transparent text-light my-lg-0 fw-bold">
                <li className="breadcrumb-item" style={{textTransform: "uppercase"}}><h3 style={{color: "white"}}>Sistem Pangkalan Data Perumahan DBKL</h3> </li>
              </ol>
            </nav>
          </div>

          <div className="col-lg-5 col-xl-4 p-0 ps-3">
            <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4 d-flex align-items-center" id="navbar">
              <div className="ms-md-auto pe-md-3 d-flex align-items-center">
               
               
              </div>
              <ul className="navbar-nav justify-content-end">
              <li  onClick={handleHome} className="nav-item px-3 d-flex align-items-center">
              <i className="fa fa-home me-sm-1 text-white"></i>
                  <NavLink to="/landing"  className={"text-white text-bold"}>Home</NavLink>
                </li>
                <li className="nav-item d-flex align-items-center"  onClick={handleLogout}  style={{cursor:"pointer"}}>
                  <span className=" text-white font-weight-bold px-0" target="_blank">
                    <i className="fa fa-user me-sm-1"></i>
                    <span className="d-sm-inline d-none">Logout</span>
                  </span>
                </li>
                {/* menu-button */}
                <li className="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a  className="nav-link text-white p-0" id="iconNavbarSidenav"  data-bs-toggle="offcanvas" data-bs-target="#bdSidebar">
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line bg-white"></i>
                  <i className="sidenav-toggler-line bg-white"></i>
                  <i className="sidenav-toggler-line bg-white"></i>
                </div>
              </a>
            </li>
                
                {/* message */}
                {/* <li className="nav-item dropdown pe-2 d-flex align-items-center">
                  <a className="nav-link text-white p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa fa-bell cursor-pointer"></i>
                  </a>
                  
                </li> */}
                {/* Message-end */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
