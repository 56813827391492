import React from "react";

const SliderCard = ({ data, title }) => {
  return (
    <div className="card mx-1 shadow-none" style={{ color: "black" }}>
      <div className="card-body ">
        <div className="row g-3">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <div className="card bg-light" style={{ height: "100%" }}>
              <div className="card-body ">
                <h3 className="card-title text-start" style={{ fontSize: "1.7em" }}>{title || "Area Name"}</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            {/* rental Section */}
            <div className="card p-2">
              <div className="card-body p-0 px-2">
                <div className="row">
                  {/* Hardcoded rental Section */}
                  <p className="text-sm text-bold me-3 mb-0 mt-1 text-center text-lg-start" style={{ color: "black" }}>
                    {data?.rental?.date || "no Date"}
                  </p>
                  <div className="col-sm-6 col-md-4 text-start">
                    <span className="d-flex justify-content-between align-items-center">
                      <h4 className="font m-0 mt-2 mt-lg-0 " style={{ color: "#5e72e4" }}>SEWA</h4>
                    </span>

                    <div className="d-flex align-items-center justify-content-lg-start justify-content-center " style={{ flexWrap: "wrap" }}>
                      <p className="fw-bolder fs-5 m-0">
                        {data?.rental?.total || ""}
                      </p>
                      <span className="fw-bolder m-0 mx-2">({data?.rental?.totalAccounts || ""})</span>
                    </div>

                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className=" font m-0 mt-2 mt-lg-0" style={{ color: "#3c9111" }}>AKTIF</h4>

                    <div className="d-flex align-items-center justify-content-lg-start justify-content-center " style={{ flexWrap: "wrap" }}>
                      <p className="fw-bolder fs-5 m-0 ">
                        {data?.rental?.totalActive || ""}
                      </p>
                      <span className="fw-bolder m-0 mx-2">({data?.rental?.totalAkaunAktif || ""})</span>
                    </div>

                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-danger font m-0 mt-2 mt-lg-0">TIDAK AKTIF</h4>

                    <div className="d-flex align-items-center justify-content-lg-start justify-content-center " style={{flexWrap:"wrap"}}>
                    <p className="fw-bolder fs-5 m-0">
                      {data?.rental?.totalNonActive || ""}
                    </p>
                      <span className="fw-bolder  m-0 mx-2">({data?.rental?.totalAkaunTidakAktif || ""})</span>
                      </div>  
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
