import { useState, useEffect } from "react";

import BarChart from "./BarChart";
import SimpleSlider from "./Slider";
import axios from "axios";

const Home = () => {

    const [sliders, setSliders] = useState([])
    const [charts, setChart] = useState([])

    useEffect(() => {
        const slidersValues = async() =>{

            const slideRes = await axios.post('http://bpohasil.click:8080/ca/aircards');
            
            setSliders(slideRes.data.values)

            const chartRes = await axios.post('http://bpohasil.click:8080/ca/airdashchart');
            
            setChart(chartRes.data)
        }
        slidersValues();
        
      },[]);
console.log(charts)
    return (
        <div className="container-fluid  ">
            <div className="mx-4 mx-lg-3">
                <SimpleSlider data={sliders}/>
            </div>
                <div className="row mt-4 mb-3 g-2">

                    <div className="col-lg-6">
                        <div className="card rounded-2 shadow-lg" >
                            <div className="card-body">
                                <h5>JUMLAH AKAUN</h5>
                                <BarChart  data={charts?.jumlah_akaun}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card rounded-2 shadow-lg" >
                            <div className="card-body">
                            <h5>JUMLAH CAJ</h5>
                            <BarChart  data={charts?.jumlah_caj}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card rounded-2 shadow-lg" >
                            <div className="card-body">
                            <h5>JUMLAH TUNGGAKAN</h5>
                            <BarChart  data={charts?.jumlah_tunggakan}/>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="card rounded-2 shadow-lg" >
                            <div className="card-body">
                            <h5>JUMLAH BAYARAN</h5>
                            <BarChart  data={charts?.jumlah_bayaran}/>
                            </div>
                        </div>
                    </div>

                    

                </div>
        </div>
    );
}

export default Home;