import { useEffect, useState } from "react";
import Card from "../../Zone/Tunggakan/Card";
import Table from "./Table";
import axios from "axios";
import { useParams } from "react-router-dom";

const UserTable = ({hide}) => {
    const [data, setData] = useState({ card:[],users: [] });
    const token = localStorage.getItem('token')

    const { id } = useParams();

    useEffect(() => {
         async function getAllCustomersKawasan() {
           const response = await axios.post(
             "http://bpohasil.click:8080/ca/getallsewakawasancustomerstable",
             {
               kawasan_id: id,
               type: "tunggakan",
               token: token,
             }
           );
           
           setData(response.data);
         }
        
          
         getAllCustomersKawasan();

      
       
       }, [id]);

    return ( 
        <div className="">
               <div className={`row g-3 my-2 ${hide==='true'?"d-none":""}`}>
                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="JUMLAH TUNGGAKAN" value={data.total_amount} icon="fas fa-user" color=" rgb(94, 114, 227)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="JUMLAH PENGHUNI AKTIF" value={data.total_amount_active} icon="fas fa-user" color=" rgb(210, 117, 233)" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="JUMLAH PENGHUNI TIDAK AKTIF" value={data.total_amount_non_active} icon="fas fa-user" color="orange" />}
                    </div>

                    <div className='col-md-6 col-lg-3'> {/* Ensure correct column classes */}
                         {data && <Card title="JUMLAH PENGHUNI" value={data.total_accounts} icon="fas fa-user" color=" rgb(129, 27, 197)" />}
                    </div>
               </div>
               <Table data={data} hide={hide}/>
          </div>
     );
}
 
export default UserTable;