import { Route, Routes } from "react-router-dom";
import Landing from "./Landing";
import Zone from "./Air/Zone/Zone";
//zone links
import ZoneCaj from "./Air/Zone/Caj/Zone"
import ZoneBayaran from "./Air/Zone/Bayaran/Zone"
import ZoneTunggakan from "./Air/Zone/Tunggakan/Zone"

import UserTable from "./Air/UserTable/UserTable";
import UserTableBayaran from "./Air/UserTable/Bayaran/UserTable";
import UserTableTunggakan from "./Air/UserTable/Tunggakan/UserTable";

import ActiveTable from "./Air/Status/ActiveTable";
import NonActiveTable from "./Air/Status/NonActiveTable";

import Home from "./Air/Home/Home";
import Uploader from "./Air/Home/Uploader";

const AirRoute = () => {
    return ( 
        <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='*' element={<Home/>}/>
        <Route path='/landing' element={<Landing/>}/>
        <Route path='/zone' element={<Zone/>}/>

          <Route path='/utama/caj/zone2/:id' element={<ZoneCaj/>}/>
          <Route path='/utama/caj/zone4/:id' element={<ZoneCaj/>}/>
          <Route path='/utama/caj/zone1/:id' element={<ZoneCaj/>}/>
          <Route path='/utama/caj/zone3/:id' element={<ZoneCaj/>}/>

          <Route path='/utama/bayaran/zone2/:id' element={<ZoneBayaran/>}/>
          <Route path='/utama/bayaran/zone4/:id' element={<ZoneBayaran/>}/>
          <Route path='/utama/bayaran/zone1/:id' element={<ZoneBayaran/>}/>
          <Route path='/utama/bayaran/zone3/:id' element={<ZoneBayaran/>}/>

          <Route path='/utama/tunggakan/zone2/:id' element={<ZoneTunggakan/>}/>
          <Route path='/utama/tunggakan/zone4/:id' element={<ZoneTunggakan/>}/>
          <Route path='/utama/tunggakan/zone1/:id' element={<ZoneTunggakan/>}/>
          <Route path='/utama/tunggakan/zone3/:id' element={<ZoneTunggakan/>}/>


          <Route path='/profil/caj/zone2/:id' element={<UserTable/>}/>
          <Route path='/profil/caj/zone4/:id' element={<UserTable/>}/>
          <Route path='/profil/caj/zone1/:id' element={<UserTable/>}/>
          <Route path='/profil/caj/zone3/:id' element={<UserTable/>}/>

          <Route path='/profil/bayaran/zone2/:id' element={<UserTableBayaran/>}/>
          <Route path='/profil/bayaran/zone4/:id' element={<UserTableBayaran/>}/>
          <Route path='/profil/bayaran/zone1/:id' element={<UserTableBayaran/>}/>
          <Route path='/profil/bayaran/zone3/:id' element={<UserTableBayaran/>}/>

          <Route path='/profil/tunggakan/zone2/:id' element={<UserTableTunggakan/>}/>
          <Route path='/profil/tunggakan/zone4/:id' element={<UserTableTunggakan/>}/>
          <Route path='/profil/tunggakan/zone1/:id' element={<UserTableTunggakan/>}/>
          <Route path='/profil/tunggakan/zone3/:id' element={<UserTableTunggakan/>}/>

          <Route path='/profil/active' element={<ActiveTable/>}/>
          <Route path='/profil/nonactive' element={<NonActiveTable/>}/>


        <Route path="/uploader" element={<Uploader/>}/>

      </Routes>
     );
}
 
export default AirRoute;