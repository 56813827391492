import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

const Table = () => {
  
  const [data, setData] = useState([]);
  

  const users = data?.customers || [];
  let customersList = [];

  const { id } = useParams();

    useEffect(() => {
         async function getAllCustomersKawasan() {
           const response = await axios.post(
             "http://bpohasil.click:8080/ca/getallairkawasancustomerstable",
             {
               kawasan_id: id,
               type: "tunggakan",
              
             }
           );
         
           setData(response.data);
         }
        
          
         getAllCustomersKawasan();

      
       
       }, [id]);
  

  for (let index = 0; index < users.length; index++) {
    const element = users[index];

      customersList.push(
        <tr key={index}>
        <td>
          <div className="d-flex py-1">
            <div>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s" className="avatar avatar-sm me-3" alt={element?.NAMA} />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="mb-0 text-xs">{element?.customer?.NAMA}</h6>
              <p className="text-xs text-secondary mb-0">{element?.customer?.NO_AKAUN}</p>
            </div>
          </div>
        </td>
        <td className="address-column" style={{ maxWidth: '150px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
          <p className="text-xs font-weight-bold mb-0">{element?.customer?.NO_UNIT}</p>
          <p className="text-xs text-secondary mb-0">{element?.customer?.NO_HARTA}</p>
        </td>
        <td>
          <p className="text-xs font-weight-bold mb-0">{element?.customer?.KATEGORI_SEWAAN}</p>
          <p className="text-xs text-secondary mb-0">{element?.customer?.KAWASAN_ID}</p>
        </td>
        <td>
         {element?.customer?.status === "AKTIF"? <span className={'badge badge-sm badge-success'} >{element?.customer?.status}</span> : <span className={'badge badge-sm badge-danger'} >{element?.customer?.status}</span> }
        </td>
        <td className="align-middle text-center text-sm">
          <span className="text-secondary text-xs font-weight-bold">RM {element?.customer?.BAKI_AKHIR}</span>
        </td>
        
        <td className="align-middle text-center">
          <span className="text-secondary text-xs font-weight-bold">
            <Link to={`/profile/${element.id}`} className='text-primary'>View</Link>
          </span>
        </td>
        </tr>
      )
  }

 

  return (
    <div>
      <div className="card">
        <div className="card-body ">
          
          <div className='card-text' style={{ maxWidth: "100%", maxHeight: "67vh", overflow: "auto" }}>
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>
                    Customer Name<br /><span className='text-secondary'>Account Number</span>
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>No. Unit</th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>KATEGORI SEWAAN
                    <br /><span className='text-secondary'>KOD KAWASAN</span>
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>Status</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>BAKI AKHIR</th>
                  
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {customersList}
                
              </tbody>
            </table>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
