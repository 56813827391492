import React from "react";
const numberFormatter = new Intl.NumberFormat();


const SliderCard = ({data,title}) => {
  return (
    <div className="card mx-1 shadow-none" style={{ color: "black" }}>
      <div className="card-body ">
        <div className="row">
          <div className="col-lg-3 mb-3 mb-lg-0">
            <div className="card bg-light" style={{ height: "100%" }}>
              <div className="card-body ">
                <h3 className="card-title text-start" style={{fontSize: "1.6em"}}>{title || "Area Name"}</h3>
              </div>
            </div>
          </div>

          <div className="col-lg-9">
            {/* Water Section */}
            <div className="card p-2">
              <div className="card-body p-0 px-2">  
                <div className="row">
                  {/* Hardcoded Water Section */}
                  <div className="col-sm-6 col-md-4 text-start">
                    <span className="d-flex justify-content-between align-items-center">
                      <h4 className="font" style={{ color: "#5e72e4" }}>Air</h4>
                      <p className="text-sm text-bold me-3 mt-1" style={{ color: "black" }}>
                        {data?.air?.date}
                      </p>
                    </span>
                    <p className="fw-bolder fs-5 m-0">
                     RM {numberFormatter.format(data?.air?.total)}
                      <span className="fw-bolder m-0 mx-2">( {numberFormatter.format(data?.air?.totalAccounts)})</span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-success font">AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                      RM {numberFormatter.format(data?.air?.totalActive)}
                      <span className="fw-bolder m-0 mx-2">({numberFormatter.format(data?.air?.totalAkaunAktif)})</span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-danger font">TIDAK AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                    RM {numberFormatter.format(data?.air?.totalNonActive)}
                      <span className="fw-bolder m-0 mx-2">({numberFormatter.format(data?.air?.totalAkaunTidakAktif)})</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

         

          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
