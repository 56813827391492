import { Route, Routes } from "react-router-dom";
import Landing from "./Landing.js"
import ZoneCaj from "./Sewa/Zone/Caj/Zone";
import ZoneBayaran from "./Sewa/Zone/Bayaran/Zone";
import ZoneTunggakan from "./Sewa/Zone/Tunggakan/Zone";
import Home from "./Sewa/Home/Home";
import UserTable from "./Sewa/UserTable/UserTable";
import UserTableBayaran from "./Sewa/UserTable/Bayaran/UserTable";
import UserTableTunggakan from "./Sewa/UserTable/Tunggakan/UserTable";
import Uploader from "./Sewa/Uploader";
import ActiveTable from "./Sewa/Status/ActiveTable";
import NonActiveTable from "./Sewa/Status/NonActiveTable";

const SewaRoute = () => {
    return ( 
        <Routes>
          
          <Route path="/dashboard" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path='/utama/caj/zone2/:id' element={<ZoneCaj/>}/>
          <Route path='/utama/caj/zone4/:id' element={<ZoneCaj/>}/>
          <Route path='/utama/bayaran/zone2/:id' element={<ZoneBayaran/>}/>
          <Route path='/utama/bayaran/zone4/:id' element={<ZoneBayaran/>}/>
          <Route path='/utama/tunggakan/zone2/:id' element={<ZoneTunggakan/>}/>
          <Route path='/utama/tunggakan/zone4/:id' element={<ZoneTunggakan/>}/>

          <Route path='/profil/caj/zone2/:id' element={<UserTable/>}/>
          <Route path='/profil/caj/zone4/:id' element={<UserTable/>}/>
          <Route path='/profil/bayaran/zone2/:id' element={<UserTableBayaran/>}/>
          <Route path='/profil/bayaran/zone4/:id' element={<UserTableBayaran/>}/>
          <Route path='/profil/tunggakan/zone2/:id' element={<UserTableTunggakan/>}/>
          <Route path='/profil/tunggakan/zone4/:id' element={<UserTableTunggakan/>}/>

          <Route path='/profil/active' element={<ActiveTable/>}/>
          <Route path='/profil/nonactive' element={<NonActiveTable/>}/>
          <Route path="/uploader" element={<Uploader/>}/>
        </Routes>
     );
}
 
export default SewaRoute;