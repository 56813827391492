import { useEffect, useState } from 'react';
import img from '../../assets/logo.png';
import './SideNav.css'
import Links from './Links';
import LinksAkaun from './LinksAkaun';
import SubSideBar from './SubSideBar';
import { Link, NavLink } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';

const Sidebar = () => {
    const [subMenu, setSubMenu] = useState(false)
    const [title, setTitle] = useState(null)
    const [MainTitle, setMainTitle] = useState(null)

    // const[data,setData] = useState(null)
    const[send,setSend] = useState(null)

  useEffect(() => {
    if (!subMenu) {
      document.getElementById('sub').style.transform = "translateX(-700px)"
    } else {
      document.getElementById('sub').style.transform = "translateX(0)"

    }
  }, [subMenu])

  const {data} = useFetch('http://bpohasil.click:8080/ca/totalkawasansewa')

    return (
        <div id="bdSidebar" class=" bg-white d-flex d-flex ms-2 mt-2 flex-column flex-shrink-0  offcanvas-xl offcanvas-start" style={{ width: "280px", borderRadius: '20px', color: "black" }}>
            <div style={{ position: "relative" }}>
            <SubSideBar MainTitle={MainTitle}  setSubMenu={setSubMenu} title={title} data={send}/>
                {/* logo */}
                <Link to="/" className="navbar-brand ps-3 pt-3 pb-2">
                    <img src={img} className="m-2" style={{ width: '40%' }} alt="Logo" />
                    <p className="fw-bold h6 ms-4" style={{ color: 'black' }}>PERUMAHAN</p>
                    {/* <p>{MainTitle}</p> */}
                </Link>
                <div style={{ maxHeight: "80vh", overflow: 'auto' }}>
                <ul class="mynav bg-white nav nav-pills flex-column mb-auto px-2" style={{ color: 'black', fontWeight: "bold" }}>


                    <li className='mb-2 ms-2' onClick={()=>setMainTitle('utama')}>
                        <Links  name="HALAMAN UTAMA" setSubMenu={setSubMenu} setTitle={setTitle} data={data} setSend={setSend}/>
                    </li>

                    <li className='mb-2 ms-2 '  onClick={()=>setMainTitle('profil')}>
                        <Links  name="PROFIL PENGHUNI" setSubMenu={setSubMenu} setTitle={setTitle} data={data} setSend={setSend}/>
                    </li>

                    <li className='mb-2 ms-2 'style={{fontWeight: "bold"}} >
                        <LinksAkaun name="STATUS AKAUN" />
                        
                    </li>

                    {/* <li className='mb-2 ms-2 text-dark'onClick={()=>setMainTitle('report')}>
                        <Links name=" REPORT" setSubMenu={setSubMenu} setTitle={setTitle} data={data} setSend={setSend}/>
                    </li> */}
                    
                    
                    <li className='mb-2 ms-2 text-dark'>
                        
                      <NavLink to={`/uploader`} className="text-dark" style={{fontSize: "1.3em", }}>
                      <i className="fa-solid fa-cloud-arrow-up" style={{marginRight: ".5em"}}></i> 
                      <i>UPLOADER</i></NavLink>
                    </li>

                </ul>
                </div>
                <hr />
            </div>
        </div>
    );
};
export default Sidebar;
