import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/argon-dashboard.css';
//other pages
import Login from './components/Login';
import Landing from './components/Landing';
// Components
import Sidebar from './components/Side&Nav/Sidebar';
import Navbar from './components/Side&Nav/Navbar';
// sewa

// other Links
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AirRoute from './components/AirRoute';
import SewaRoute from './components/SewaRoute';
import AirSidebar from './components/Side&Nav/AirSidebar';

let routeLinks = localStorage.getItem('paths')
function App() {
  const token = localStorage.getItem('token')
  const paths = localStorage.getItem('paths')
  if (!token) {
   return <Login />
  } 


  if(!paths){
    return <Landing />
  }
    return (
    <BrowserRouter>
    <div class="App container-fluid p-0 d-flex" style={{minHeight:"100vh"}}>
    {routeLinks === "sewa" ?  
      <Sidebar/>
      :
      <AirSidebar />
    }
      <div className="bg flex-fill" style={{maxHeight:"99vh",overflow:"auto"}}>
        <Navbar/>
          <div class=" flex-fill">

            {/* page content start hear */}
              <div className="py-3 px-lg-3">
                {routeLinks === "sewa" ?  
                <SewaRoute/> 
                : 
                <AirRoute />}
        
              </div>
          </div>
       </div>
    
    </div>
</BrowserRouter>
  )
}

export default App;
