import { useState } from "react";
import { NavLink } from "react-router-dom";

const LinksAkaun = ({name,setSubMenu,setTitle,data,setSend}) => {
    const [hide, setHide] = useState(false)
    return (
        <div style={{ cursor: 'pointer', color: "black" }} >

            <span className="d-flex justify-content-between align-items-center me-3" onClick={() => setHide(!hide)}>
                <p className="fs-5  mb-1" style={{fontWeight: "bold"}} >
                    <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                   {name || "No Name"}
                </p>
                <i class={`fa-solid ${hide ? "fa-angle-up" : "fa-angle-down"} `}></i>
            </span>

            <ul className={`ps-2 links ${!hide ? "d-none" : "d-block"}`}  style={{color:"rgb(95,95,95)"}}>
                <li className="m-2"  >
                    <span className="d-flex justify-content-between align-items-center me-3">
                    <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                    <NavLink className="text-dark" to={"/profil/active"} >AKTIF</NavLink> 

                        
                    </span>
                </li>
                <li className="m-2" >
                    <span className="d-flex justify-content-between align-items-center me-3">
                    <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                    <NavLink className="text-dark" to={"/profil/nonactive"} >TIDAK AKTIF</NavLink> 
                        
                    </span>
                </li>
                
               
             
            </ul>
        </div>
    );
}

export default LinksAkaun;