import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Register the required scales and components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({data}) => {
  
  // Static data for demonstration purposes
  const chartConfig = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "AKTIF",
        data: data?.active,
        backgroundColor: "#3c9111",
        borderColor: "#3c9111",
        borderWidth: 1,
        hoverBackgroundColor: "#324cdd",
        hoverBorderColor: "#324cdd",
      },
      {
        label: "TIDAK AKTIF",
        data: data?.nonactive,
        backgroundColor: "red",
        borderColor: "red",
        borderWidth: 1,
        hoverBackgroundColor: "#f4365c",
        hoverBorderColor: "#f4365c",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { grid: { display: false } },
      y: { grid: { color: "#e9e9e9" }, beginAtZero: true },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Total: ${tooltipItem.raw}`,
        },
      },
      legend: { display: true },
    },
  };

  return (
    <div style={{ height: "300px" }}>
      <Bar data={chartConfig} options={options} />
    </div>
  );
};

export default BarChart;
