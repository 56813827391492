import Card from "./Card";
import DoubleBar from "./DoubleBar";
import ImgCard from "./ImgCard";
import Table from "./Table";

const Zone = () => {
    return (
        <div className='container-fluid  p-2 p-lg-0'>
            <div className="row mb-3 g-3">
                <div className="col-lg-3 col-md-6">
                    <Card title="Revenue" value={5000} percentage={5.5} icon="fa fa-user" color="#5e72e4" />
                </div>
                <div className="col-lg-3 col-md-6">
                    <Card title="Revenue" value={5000} percentage={5.5} icon="fa fa-user" color="#5e72e4" />
                </div>
                <div className="col-lg-3 col-md-6">
                    <Card title="Revenue" value={5000} percentage={5.5} icon="fa fa-user" color="#5e72e4" />
                </div>
                <div className="col-lg-3 col-md-6">
                    <Card title="Revenue" value={5000} percentage={5.5} icon="fa fa-user" color="#5e72e4" />
                </div>
            </div>

            <div className="row my-3">
                <div className="col-lg-7">
                    <div className="card rounded-2">
                        <div className="card-body">
                            <DoubleBar />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <ImgCard />
                </div>
            </div>

            <div className="card px-1 py-2" style={{ minHeight: "40vh",overflow:'auto' }}>
            <p className="ms-2 mb-2 m-0 fw-bold">Customer List</p>
            <div className='card-body p-1'>
                <Table/>
            </div>
            </div>

        </div>
    );
}

export default Zone;