import { useState } from "react";

const Links = ({name,setSubMenu,setTitle,data,setSend}) => {
    const [hide, setHide] = useState(false)
    return (
        <div style={{ cursor: 'pointer', color: "black" }} >

            <span className="d-flex justify-content-between align-items-center me-3" onClick={() => setHide(!hide)}>
                <p className="fs-5  mb-1" style={{color: "black", fontWeight: "bold"}}>
                    <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                   {name || "No Name"}
                </p>
                <i class={`fa-solid ${hide ? "fa-angle-up" : "fa-angle-down"} `}></i>
            </span>

            <ul className={`ps-2 links ${!hide ? "d-none" : "d-block"}`}  style={{color:"black", fontWeight: "bold"}}>
                <li className="m-2 text-black text-bold"  onClick={()=>{
                            setSubMenu(true)
                            setTitle('CAJ')
                            setSend(data)

                        }}>
                    <span className="d-flex justify-content-between align-items-center me-3">
                        <p className="mb-1 " style={{fontSize:"1rem", fontWeight:"bold"}} >
                            <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                            CAJ
                        </p>

                        <i className="fa-solid  fa-angle-right"></i>
                    </span>
                </li>
                <li className="m-2" onClick={()=>{
                            setSubMenu(true)
                            setTitle('BAYARAN')
                            setSend(data)

                        }}>
                    <span className="d-flex justify-content-between align-items-center me-3">
                        <p className="mb-1 " style={{fontSize:"1rem" , fontWeight:"bold"}} >
                            <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                            BAYARAN
                        </p>

                        <i className="fa-solid  fa-angle-right"></i>
                    </span>
                </li>
                <li className="m-2" onClick={()=>{
                            setSubMenu(true)
                            setTitle('TUNGGAKAN')
                            setSend(data)

                        }}>
                    <span className="d-flex justify-content-between align-items-center me-3">
                        <p className="mb-1 " style={{fontSize:"1rem", fontWeight:"bold"}} >
                            <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                            TUNGGAKAN
                        </p>

                        <i className="fa-solid  fa-angle-right"></i>
                    </span>
                </li>
                {/* <li className="m-2" onClick={()=>{
                            setSubMenu(true)
                            setTitle('JUMLAH AKAUN')
                            setSend(data)
                        }}>
                    <span className="d-flex justify-content-between align-items-center me-3">
                        <p className="mb-1 " style={{fontSize:"1rem"}} >
                            <i className=" me-2 fa-solid fa-file-invoice" style={{ color: "orange" }}></i>
                            JUMLAH AKAUN
                        </p>

                        <i className="fa-solid  fa-angle-right"></i>
                    </span>
                </li> */}
             
            </ul>
        </div>
    );
}

export default Links;