import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

const Table = ({ data,hide }) => {
  const [status, setStatus] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  let users = data?.customers || [];
  let customersList = [];



  for (let index = 0; index < users.length; index++) {

    const element = users[index];

    if(status == 1){
      

      customersList.push(
        <tr key={index}>
        <td>
          <div className="d-flex py-1">
            <div>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s" className="avatar avatar-sm me-3" alt={element.NAMA} />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="mb-0 text-xs">{element.NAMA}</h6>
              <p className="text-xs text-secondary mb-0">{element.NO_AKAUN}</p>
            </div>
          </div>
        </td>
        <td className="address-column" style={{ maxWidth: '150px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
          <p className="text-xs font-weight-bold mb-0">{element.NO_UNIT}</p>
          <p className="text-xs text-secondary mb-0">{element.NO_HARTA}</p>
        </td>
        <td>
          <p className="text-xs font-weight-bold mb-0">{element.KATEGORI_SEWAAN}</p>
          <p className="text-xs text-secondary mb-0">{element.KAWASAN_ID}</p>
        </td>
        <td>
         {element.STATUS === "AKTIF"? <span className={'badge badge-sm badge-success'} >{element.STATUS}</span> : <span className={'badge badge-sm badge-danger'} >{element.STATUS}</span> }
         
          
        </td>
        <td className="align-middle text-center text-sm">
          <span className="text-secondary text-xs font-weight-bold">RM {element.BAYARAN}</span>
        </td>
        <td className="align-middle text-center">
          <span className="text-secondary text-xs font-weight-bold">RM {element.CAJ_SEWA}</span>
        </td>
        <td className="align-middle text-center">
          <span className="text-secondary text-xs font-weight-bold">RM {element.TUNGGAKAN}</span>
        </td>
        <td className="align-middle text-center">
          <span className="text-secondary text-xs font-weight-bold">
            <Link to={`/profile/${element.id}`} className='text-primary'>View</Link>
          </span>
        </td>
        </tr>
      )
           

    }else{
      if(status === element.STATUS){
        customersList.push(
          <tr key={index}>
          <td>
            <div className="d-flex py-1">
              <div>
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s" className="avatar avatar-sm me-3" alt={element.NAMA} />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <h6 className="mb-0 text-xs">{element.NAMA}</h6>
                <p className="text-xs text-secondary mb-0">{element.NO_AKAUN}</p>
              </div>
            </div>
          </td>
          <td className="address-column" style={{ maxWidth: '150px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
            <p className="text-xs font-weight-bold mb-0">{element.NO_UNIT}</p>
            <p className="text-xs text-secondary mb-0">{element.NO_HARTA}</p>
          </td>
          <td>
            <p className="text-xs font-weight-bold mb-0">{element.KATEGORI_SEWAAN}</p>
            <p className="text-xs text-secondary mb-0">{element.KAWASAN_ID}</p>
          </td>
          <td>
           {element.STATUS === "AKTIF"? <span className={'badge badge-sm badge-success'} >{element.STATUS}</span> : <span className={'badge badge-sm badge-danger'} >{element.STATUS}</span> }
           
            
          </td>
          <td className="align-middle text-center text-sm">
            <span className="text-secondary text-xs font-weight-bold">RM {element.BAYARAN}</span>
          </td>
          <td className="align-middle text-center">
            <span className="text-secondary text-xs font-weight-bold">RM {element.CAJ_SEWA}</span>
          </td>
          <td className="align-middle text-center">
            <span className="text-secondary text-xs font-weight-bold">RM {element.TUNGGAKAN}</span>
          </td>
          <td className="align-middle text-center">
            <span className="text-secondary text-xs font-weight-bold">
              <Link to={`/profile/${element.id}`} className='text-primary'>View</Link>
            </span>
          </td>
          </tr>
        )
             
      }
    }
    

  }

  // const res = users.filter(user => user.STATUS === "AKTIF");

  // (checking name, email, or account number)
  // const filteredUsers = res.filter(user =>
  //   (user.NAMA?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
  //   (user.email?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
  //   (user.no_akaun_air?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  // );
  
  // const rowsPerPage = 10;
  // const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  // const currentData = filteredUsers.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(users.map(user => ({
      Name: user.NAMA,
      no_akaun_air: user.NO_AKAUN,
      NO_PENGENALAN: user.NO_PENGENALAN,
      no_harta: user.NO_HARTA,
      NoUnit: user.NO_UNIT,
      caj: user.CAJ_SEWA,
      kawasan_id: user.KAWASAN_ID,
      status: user.STATUS,
      KATEGORI_SEWAAN: user.KATEGORI_SEWAAN,
    })));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "KawasanUsersData.xlsx");
  };

  // const goToNextPage = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const goToPreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  return (
    <div>
      <div className="card">
        <div className="card-body ">
          <div className='d-flex justify-content-between'>
            <h6>Akaun Bertunggakan Tinggi</h6>
            <div className='d-flex align-items-center'>
              {/* <input
                type='search'
                className='mb-3 me-2 ps-2 py-1 rounded border-1'
                style={{outline:"none"}}
                placeholder='Search...'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update searchQuery on input change
              /> */}
              <button className='btn btn-success' onClick={() => setStatus('AKTIF')}>AKTIF</button>
              <button className='btn btn-danger mx-2' onClick={() => setStatus('TIDAK AKTIF')}>TIDAK AKTIF</button>
                <span className={hide==='true'?"d-none":""}>
              <button className='btn btn-primary' onClick={handleDownload}>Click to Download Excel File</button>
              </span>
            </div>
          </div>
          <div className='card-text' style={{ maxWidth: "100%", maxHeight: "67vh", overflow: "auto" }}>
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>
                    Customer Name<br /><span className='text-secondary'>Account Number</span>
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>No. Unit</th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>KATEGORI SEWAAN
                    <br /><span className='text-secondary'>KOD KAWASAN</span>
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>Status</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>BAYARAN</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>CAJ_SEWA</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>TUNGGAKAN</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {customersList}
                
              </tbody>
            </table>
            {/* <div className="d-flex justify-content-between p-3">
              <button onClick={goToPreviousPage} disabled={currentPage === 1} className="btn btn-secondary btn-sm">Previous</button>
              <span>Page {currentPage} of {totalPages}</span>
              <button onClick={goToNextPage} disabled={currentPage === totalPages} className="btn btn-secondary btn-sm">Next</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
