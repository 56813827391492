import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import BarChart from "./BarChart";
import Card from "./Card";
import ImgCard from "./ImgCard";
import Table from "./Table";

const Zone = () => {
    const [zoneData, setZoneData] = useState([]);
    const [customers, setCustomers] = useState([]);
    let { id } = useParams();
    let responseZone = []

    
    
    useEffect(() => {
        
        const zonValueRes = async() =>{
            responseZone = await axios.post("http://bpohasil.click:8080/ca/getallairkawasancustomers",{
            type: "caj",
            kawasan_id: id
        })
        
        setZoneData(responseZone.data)

    }
       zonValueRes();
       
      

    }, [id]);

    console.log(zoneData)

    return (
        <div className='container-fluid  p-2 p-lg-0'>
            <div className="row mb-3 g-3">
                <div className="col-lg-3 col-md-6">
                    <Card title="JUMLAH CAJ" value={zoneData.total_amount}  icon="fa fa-coins" color="#5e72e4" />
                </div>
                <div className="col-lg-3 col-md-6">
                    <Card title="JUMLAH PENGHUNI AKTIF" value={zoneData.active_accounts} icon="fa fa-users" color="#5e72e4" />
                </div>
                <div className="col-lg-3 col-md-6">
                    <Card title="JUMLAH PENGHUNI TIDAK AKTIF" value={zoneData.non_active_accounts} icon="fa fa-user" color="#5e72e4" />
                </div>
                <div className="col-lg-3 col-md-6">
                    <Card title="JUMLAH PENGHUNI" value={zoneData.total_accounts} icon="fa fa-user" color="#5e72e4" />
                </div>
            </div>

            <div className="row my-3">
                <div className="col-lg-7">
                    <div className="card rounded-2">
                        <div className="card-body">
                            <BarChart data={zoneData.chartValues} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <ImgCard />
                </div>
            </div>

            <div className="card px-1 py-2" style={{ minHeight: "40vh",overflow:'auto' }}>
            <p className="ms-2 mb-2 m-0 fw-bold" style={{color: "black"}}>SENARAI PENGHUNI</p>
            <div className='card-body p-1'>
            <Table />
                
            </div>
            </div>

        </div>
    );
}

export default Zone;