import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import SliderCard from "./SliderCard";

function SimpleSlider({data}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <SliderCard data={data?.tungakaan} title="JUMLAH TUNGGAKAN"/>
        </div>
        <div>
          <SliderCard data={data?.bayaran} title="JUMLAH BAYARAN"/>
        </div> <div>
          <SliderCard data={data?.caj} title="JUMLAH CAJ"/>
        </div> <div>
          <SliderCard data={data?.akaun} title="JUMLAH AKAUN"/>
        </div> 
      </Slider>
    </div>
  );
}

export default SimpleSlider;
