import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const ActiveTable = () => {
  const [data, setData] = useState(1);
 

  useEffect(() => {
    async function getAllCustomersKawasan() {
      const response = await axios.post(
        "http://bpohasil.click:8080/ca/getallaircustomers",{
          "type": "active"
        });
      
      setData(response.data);
    }
   
     
    getAllCustomersKawasan();

 
  
  }, []);

  console.log(data)

  let users = data?.customersactive || [];
  let customersList = [];



  for (let index = 0; index < users.length; index++) {

    const element = users[index];
    customersList.push(
        <tr key={index}>
        <td>
          <div className="d-flex py-1">
            <div>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtuphMb4mq-EcVWhMVT8FCkv5dqZGgvn_QiA&s" className="avatar avatar-sm me-3" alt={element.NAMA} />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="mb-0 text-xs">{element.NAMA}</h6>
              <p className="text-xs text-secondary mb-0">{element.NO_AKAUN}</p>
            </div>
          </div>
        </td>
        <td className="address-column" style={{ maxWidth: '150px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
          <p className="text-xs font-weight-bold mb-0">{element.NO_UNIT}</p>
          <p className="text-xs text-secondary mb-0">{element.NO_HARTA}</p>
        </td>
        <td>
          <p className="text-xs font-weight-bold mb-0">{element.EMEL}</p>
          <p className="text-xs text-secondary mb-0">{element.KAWASAN_ID}</p>
        </td>
        <td>
         {element.status === "AKTIF"? <span className={'badge badge-sm badge-success'} >{element.status}</span> : <span className={'badge badge-sm badge-danger'} >{element.status}</span> }
         
          
        </td>
        
        <td className="align-middle text-center">
          <span className="text-secondary text-xs font-weight-bold">RM {element.BAKI_AKHIR}</span>
        </td>
        <td className="align-middle text-center">
          <span className="text-secondary text-xs font-weight-bold">
            <Link to={`/profile/${element.id}`} className='text-primary'>View</Link>
          </span>
        </td>
        </tr>
      )

  }

  return (
    <div>
      <div className="card">
        <div className="card-body ">
          <div className='d-flex justify-content-between'>
            <h6>Akaun Bertunggakan Tinggi</h6>
            
          </div>
          <div className='card-text' style={{ maxWidth: "100%", maxHeight: "67vh", overflow: "auto" }}>
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>
                    Customer Name<br /><span className='text-secondary'>Account Number</span>
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>No. Unit</th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>KATEGORI SEWAAN
                    <br /><span className='text-secondary'>KOD KAWASAN</span>
                  </th>
                  <th className="text-uppercase text-xxs font-weight-bolder opacity-7 ps-2" style={{ color: "rgb(94, 114, 227)" }}>Status</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>TUNGGAKAN</th>
                  <th className="text-center text-uppercase text-xxs font-weight-bolder opacity-7" style={{ color: "rgb(94, 114, 227)" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {customersList}
                
              </tbody>
            </table>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveTable;
