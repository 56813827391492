import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";


const SubSideBar = ({ setSubMenu, title, MainTitle, data }) => {
  // to hide and show function 
  const [hide1, setHide1] = useState(false)
  const [hide2, setHide2] = useState(false)
  const [hide3, setHide3] = useState(false)
  const [hide4, setHide4] = useState(false)

  // to add link value
  const [value, setValue] = useState(null)

  useEffect(() => {
    switch (title) {
      case 'CAJ':
        setValue('caj');
        break;
      case 'BAYARAN':
        setValue('bayaran');
        break;
      case 'TUNGGAKAN':
        setValue('tunggakan');
        break;
      case 'JUMLAH AKAUN':
        setValue('akaun');
        break;
      default:
        setValue('');
        break;
    }
  }, [title])

  return (
    <>
    <div id="subakaun" style={{maxHeight:"90vh",overflow:'auto', display:"none"}}>
      <div className="border rounded d-flex justify-content-between px-2 pt-1 mx-2 mt-2 bg-primary">
        <p className="text-white" style={{ textTransform: "uppercase" }}>{title || "No Name"}</p>
        <span
          onClick={() => setSubMenu(false)}
          className=" "
          style={{ cursor: "pointer" }}
        >
          <i className="fa-solid fa-xmark text-white"></i>
        </span>
      </div>
      <ul className="nav nav-pills bg-white flex-column mynav mx-2 px-2 mt-1">
        {/* Zone 1 */}
        {data?.zone1 && data?.zone1.length > 0 ? (

          <li className="text-dark ">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide1(!hide1)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 1 {value}
              </span>
              <i
                className={`fas ${hide1 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide1 ? "d-none" : "d-block"}`}>
              {data?.zone1.map((item,index)=>(
              <NavLink key={index} to={{pathname: `${MainTitle}/${value}/zone1/${item.kawasan_no}`, state: { infoId: "info.id" }}} style={{ color: 'black' }} data={test= "test"}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}

        {/* Zone 2 */}
        {data?.zone2 && data?.zone2.length > 0 ? (
          <li className="text-dark ">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide2(!hide2)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 2 {value}
              </span>
              <i
                className={`fas ${hide2 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide2 ? "d-none" : "d-block"}`}>
            {data?.zone2.map((item,index)=>(
              <NavLink key={index} to={`${MainTitle}/${value}/zone2/${item.kawasan_no}`} style={{ color: 'black' }}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}

        {/* Zone 3 */}
        {data?.zone3 && data?.zone3.length > 0 ? (
          <li className="text-dark ">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide3(!hide3)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 3 {value}
              </span>
              <i
                className={`fas ${hide3 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide3 ? "d-none" : "d-block"}`}>
            {data?.zone3.map((item,index)=>(
              <NavLink key={index} to={`${MainTitle}/${value}/zone3/${item.kawasan_no}`} style={{ color: 'black' }}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}

        {/* Zone 4 */}
        {data?.zone4 && data?.zone4.length > 0 ? (
          <li className="text-dark ">
            <p
              className="fw-bold mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide4(!hide4)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 4 {value}
              </span>
              <i
                className={`fas ${hide4 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide4 ? "d-none" : "d-block"}`}>
            {data?.zone4.map((item,index)=>(
              <NavLink key={index} to={`${MainTitle}/${value}/zone4/${item.kawasan_no}`} style={{ color: 'black' }}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}
      </ul>

    </div>
    <div id="sub" style={{maxHeight:"90vh",overflow:'auto'}}>
      <div className="border rounded d-flex justify-content-between px-2 pt-1 mx-2 mt-2 bg-primary">
        <p className="text-white" style={{ textTransform: "uppercase" }}>{title || "No Name"}</p>
        <span
          onClick={() => setSubMenu(false)}
          className=" "
          style={{ cursor: "pointer" }}
        >
          <i className="fa-solid fa-xmark text-white"></i>
        </span>
      </div>
      <ul className="nav nav-pills bg-white flex-column mynav mx-2 px-2 mt-1">
        {/* Zone 1 */}
        {data?.zone1 && data?.zone1.length > 0 ? (

          <li className="text-dark ">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide1(!hide1)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 1 {value}
              </span>
              <i
                className={`fas ${hide1 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide1 ? "d-none" : "d-block"}`}>
              {data?.zone1.map((item,index)=>(
              <NavLink key={index} to={{pathname: `${MainTitle}/${value}/zone1/${item.kawasan_no}`, state: { infoId: "info.id" }}} style={{ color: 'black' }} data={test= "test"}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}

        {/* Zone 2 */}
        {data?.zone2 && data?.zone2.length > 0 ? (
          <li className="text-dark ">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide2(!hide2)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 2 {value}
              </span>
              <i
                className={`fas ${hide2 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide2 ? "d-none" : "d-block"}`}>
            {data?.zone2.map((item,index)=>(
              <NavLink key={index} to={`${MainTitle}/${value}/zone2/${item.kawasan_no}`} style={{ color: 'black' }}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}

        {/* Zone 3 */}
        {data?.zone3 && data?.zone3.length > 0 ? (
          <li className="text-dark ">
            <p
              className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide3(!hide3)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 3 {value}
              </span>
              <i
                className={`fas ${hide3 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide3 ? "d-none" : "d-block"}`}>
            {data?.zone3.map((item,index)=>(
              <NavLink key={index} to={`${MainTitle}/${value}/zone3/${item.kawasan_no}`} style={{ color: 'black' }}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}

        {/* Zone 4 */}
        {data?.zone4 && data?.zone4.length > 0 ? (
          <li className="text-dark ">
            <p
              className="fw-bold mb-1 d-flex align-items-center justify-content-between"
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => setHide4(!hide4)}
            >
              <span className="fw-light">
                <i className="fas fa-city me-2" style={{ color: "orange" }}></i>
                Zone 4 {value}
              </span>
              <i
                className={`fas ${hide4 ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                style={{ fontSize: ".7rem" }}
              ></i>
            </p>
            <ul className={`ps-4 text-dark ${hide4 ? "d-none" : "d-block"}`}>
            {data?.zone4.map((item,index)=>(
              <NavLink key={index} to={`${MainTitle}/${value}/zone4/${item.kawasan_no}`} style={{ color: 'black' }}>{item.nama}</NavLink>
              ))}
            </ul>
          </li>
        ) : null}
      </ul>

    </div>
    </>
    
  );
}

export default SubSideBar;