
import logo from '../assets/logo_transparent.png';

const Landing = () => {
  
    const hanldeAir=()=>{
        localStorage.setItem('paths',"air")
         window.location.replace('/')
    }
    const hanldeSewa=()=>{
        localStorage.setItem('paths',"sewa")
         window.location.replace('/')
    }
    return ( 
        <div className="App " style={{position:"absolute",top:"0",width:"100%",minHeight:"100vh",zIndex:"1000"}}>
               
               
               <div className="row d-flex justify-content-center align-items-center " style={{width:"100%",minHeight:"100vh"}}>
                <div className="col-7" >
               
               <div className="card shadow-lg" style={{minHeight:"300px", position: "relative", top:"-9em"}}>
               
                <div className="card-header text-center py-2" style={{position: "relative", top:"2em"}}>
                <img src={logo} className='logo' alt="Logo"/>
                    <h1> SELAMAT DATANG</h1>
                </div>
                <div className="card-body text-center">
                    
                    <div className="row  m-lg-5 d-flex justify-content-between align-items-center">
                    <div className="col-lg-4 " style={{cursor:"pointer" }}>
                            <button className="btn fs-1 btn-primary " style={{width:"100%", background: "#00008B"}}
                            onClick={hanldeAir}
                            >Air</button>
                            </div>
                        <div className="col-lg-4 " style={{cursor:"pointer" }}>
                            <button className="btn fs-1 text-white   " style={{width:"100%", background: "#ffa500"}}
                             onClick={hanldeSewa}
                            >Sewa</button>
                            </div>
                    </div>
                </div>
               </div>

                </div>
               </div>
            
        </div>
     );
}
 
export default Landing;